import { Badge, Flex, Text } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { resourceLanguagesAll } from "../cms/collections/resourceLanguages";
import { BackNavigation } from "../components/BackNavigation";
import { mainColors } from "../components/FilterPanel";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import { Main } from "../components/Main";
import { MarkdownContainer } from "../components/MarkdownContainer";

export const ResourceTemplate = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.image);
  const languages = resourceLanguagesAll.map((l) => l.id);
  const languageIndex = languages.findIndex(
    (l) => l === data.mdx.frontmatter.language
  );

  return (
    <Layout justifyContent="center" alignItems="center">
      <Header>
        <BackNavigation title={data.mdx.frontmatter.title} />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          maxWidth={["100%", null, null, "60ch"]}
          padding={[null, null, null, 8]}
        >
          <GatsbyImage image={image} alt={data.mdx.frontmatter.description} />
          <Text
            my="2"
            mx="4"
            fontSize="sm"
            textShadow="md"
            color="blackAlpha.700"
            textAlign="center"
          >
            <Badge
              display="inline-block"
              variant="outline"
              alignItems="center"
              borderRadius="sm"
              mr="2"
              colorScheme={mainColors[languageIndex % mainColors.length]}
            >
              {data.mdx.frontmatter.language}
            </Badge>
            {data.mdx.frontmatter.description}
          </Text>
          <MarkdownContainer>
            <MDXRenderer margin="4">{data.mdx.body}</MDXRenderer>
          </MarkdownContainer>
        </Flex>
      </Main>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/content/resources/" }) {
      frontmatter {
        title
        description
        language
        category
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

export default ResourceTemplate;
